<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        Коллаборация
      </h2>
      <h3>
        Личностный опросник К.Томаса
      </h3>
      <div class="collaboration-desc">
        Инструкция: Вам предлагается дать ответы на 30 пар суждений (высказываний). Каждое из них в большей или меньшей степени может соответствовать типичному вашему поведению в конфликтных ситуациях и спорах. В каждой паре утверждений обязательно отметьте присущий вам вариант поведения («а» или «б»).
      </div>

      <div class="d-flex flex-column">

        <div class="question-radio">
          1)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[0].choice" label="А">
              А) Иногда я предоставляю возможность другим взять на себя ответственность за решение спорного вопроса
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[0].choice" label="Б">
              Б) Прежде, чем обсуждать то, в чем мы расходимся, я стремлюсь обратить внимание на то, в чем оба согласны
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          2)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[1].choice" label="А">
              А) Я стараюсь найти компромиссное решение
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[1].choice" label="Б">
              Б) Я пытаюсь наладить его с учетом интересов другого человека и моих собственных
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          3)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[2].choice" label="А">
              А) Обычно я настойчиво стремлюсь добиться своего
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[2].choice" label="Б">
              Б) Иногда я жертвую собственными интересами ради другого человека
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          4)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[3].choice" label="А">
              А) Я стараюсь найти компромиссное решение
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[3].choice" label="Б">
              Б) Я стараюсь не видеть чувства другого человека
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          5)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[4].choice" label="А">
              А) Во время улаживания спорных ситуаций, я пытаюсь найти поддержку у друга
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[4].choice" label="Б">
              Б) Я стараюсь сделать все, чтобы избежать ненужной напряженности
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          6)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[5].choice" label="А">
              А) Я пытаюсь избежать для себя неприятности
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[5].choice" label="Б">
              Б) Я стараюсь добиться своего
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          7)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[6].choice" label="А">
              А) Я стараюсь отложить решение спорного вопроса, с тем чтобы со временем решить его окончательно
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[6].choice" label="Б">
              Б) Я считаю возможным в чем-то уступить, чтобы добиться лучшего
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          8)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[7].choice" label="А">
              А) Обычно я настойчиво стремлюсь добиться своего
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[7].choice" label="Б">
              Б) Я прежде всего стараюсь понять то, в чем заключаются все затронутые интересы и спорные вопросы
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          9)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[8].choice" label="А">
              А) Думаю, что не всегда стоит волноваться из-за каких-то возникших разногласий
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[8].choice" label="Б">
              Б) Я предпринимаю усилия, чтобы добиться своего
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          10)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[9].choice" label="А">
              А) Я упорно стремлюсь добиться своего
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[9].choice" label="Б">
              Б) Я пытаюсь найти компромиссное решение
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          11)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[10].choice" label="А">
              А) Я стремлюсь ясно представить то, в чем заключаются все затронутые интересы и спорные вопросы
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[10].choice" label="Б">
              Б) Я стараюсь успокоить другого человека и главным образом сохранить наши отношения
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          12)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[11].choice" label="А">
              А) Часто избегаю занимать позицию, которая может вызвать споры
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[11].choice" label="Б">
              Б) Я даю возможность другому в чем-то остаться при своем мнении, если он также идет мне навстречу
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          13)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[12].choice" label="А">
              А) Я предлагаю среднюю позицию
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[12].choice" label="Б">
              Б) Я настаиваю, чтобы все было сделано по-моему
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          14)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[13].choice" label="А">
              А) Я сообщаю другому человеку свою точку зрения и спрашиваю о его взглядах
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[13].choice" label="Б">
              Б) Я пытаюсь показать другому человеку логику моей позиции и преимущество моих взглядов
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          15)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[14].choice" label="А">
              А) Я стараюсь успокоить другого человека и сохранить наши отношения
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[14].choice" label="Б">
              Б) Я стараюсь сделать все необходимое, чтобы избежать напряжения
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          16)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[15].choice" label="А">
              А) Я стараюсь не задеть чувств другого
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[15].choice" label="Б">
              Б) Я обычно пытаюсь убедить другого человека в преимуществах моей позиции
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          17)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[16].choice" label="А">
              А) Обычно я настойчиво стремлюсь добиться своего
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[16].choice" label="Б">
              Б) Я стараюсь сделать все, чтобы избежать ненужной напряженности
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          18)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[17].choice" label="А">
              А) Если это сделает другого счастливым, я дам ему возможность настоять на своем
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[17].choice" label="Б">
              Б) Я дам другому возможность остаться при своем мнении, если он пойдет мне навстречу
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          19)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[18].choice" label="А">
              А) Прежде всего я пытаюсь понять то, в чем заключаются все затронутые интересы и спорные вопросы
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[18].choice" label="Б">
              Б) Я стараюсь отложить спорные вопросы, с тем чтобы со временем их окончательно решить
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          20)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[19].choice" label="А">
              А) Я пытаюсь немедленно преодолеть наши разногласия
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[19].choice" label="Б">
              Б) Я стараюсь найти наилучшее сочетание выгод и потерь для нас обоих
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          21)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[20].choice" label="А">
              А) Ведя переговоры, стараюсь быть внимательным к другому
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[20].choice" label="Б">
              Б) Я всегда склоняюсь к прямому обсуждению проблемы
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          22)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[21].choice" label="А">
              А) Я пытаюсь найти позицию, которая находится посередине между моей и другого человека
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[21].choice" label="Б">
              Б) Я отстаиваю свою позицию
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          23)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[22].choice" label="А">
              А) Как правило, я стараюсь удовлетворить желания каждого из нас
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[22].choice" label="Б">
              Б) Иногда предоставляю другим взять на себя ответственность за решение спорного вопроса
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          24)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[23].choice" label="А">
              А) Если позиция другого человека кажется ему очень важной, я стараюсь идти ему навстречу
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[23].choice" label="Б">
              Б) Я стараюсь убедить другого человека пойти на компромисс
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          25)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[24].choice" label="А">
              А) Я пытаюсь убедить другого человека в своей правоте
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[24].choice" label="Б">
              Б) Ведя переговоры, я стараюсь быть внимательным к интересам другого
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          26)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[25].choice" label="А">
              А) Я обычно предлагаю среднюю позицию
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[25].choice" label="Б">
              Б) Я почти всегда стремлюсь удовлетворить интересы каждого из нас
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          27)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[26].choice" label="А">
              А) Часто стремлюсь избежать споров
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[26].choice" label="Б">
              Б) Если это сделает другого человека счастливым, я дам ему возможность настоять на своем
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          28)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[27].choice" label="А">
              А) Обычно я настойчиво стремлюсь добиться своего
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[27].choice" label="Б">
              Б) Улаживая ситуации, я стремлюсь найти поддержку у другого
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          29)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[28].choice" label="А">
              А) Я предлагаю среднюю позицию
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[28].choice" label="Б">
              Б) Думаю, что не всегда стоит волноваться из-за возникших разногласий
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          30)
          <div class="question-radio-list">
            <el-radio v-model="answersThomasQuestionnaire[29].choice" label="А">
              А) Я стараюсь не задевать чувства другого человека
            </el-radio>
            <el-radio v-model="answersThomasQuestionnaire[29].choice" label="Б">
              Б) Я всегда занимаю такую позицию в споре, чтобы мы оба могли достигнуть успеха
            </el-radio>
          </div>
        </div>
      </div>

      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendThomasQuestionnaireResults(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersThomasQuestionnaire: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" },
        { choice: "", question: "11" },
        { choice: "", question: "12" },
        { choice: "", question: "13" },
        { choice: "", question: "14" },
        { choice: "", question: "15" },
        { choice: "", question: "16" },
        { choice: "", question: "17" },
        { choice: "", question: "18" },
        { choice: "", question: "19" },
        { choice: "", question: "20" },
        { choice: "", question: "21" },
        { choice: "", question: "22" },
        { choice: "", question: "23" },
        { choice: "", question: "24" },
        { choice: "", question: "25" },
        { choice: "", question: "26" },
        { choice: "", question: "27" },
        { choice: "", question: "28" },
        { choice: "", question: "29" },
        { choice: "", question: "30" }
      ],
      score: null,
    }
  },
  methods: {
    validateAnswers() {
      return this.answersThomasQuestionnaire.some(
          (answer) => answer.choice.trim() === ""
      );
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendThomasQuestionnaireResults(timer) {

      if (!timer && this.validateAnswers()) {

        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Ответьте на все вопросы',
          type: 'error'
        })
      }

      // rivalry Соперничество
      // cooperation Сотрудничество
      // compromise Компромисс
      // avoid Избежание
      // adaptation Соперничество

      const scores = {
        rivalry: 0,
        cooperation: 0,
        compromise: 0,
        avoid: 0,
        adaptation: 0
      };

      const scoringRules = {
        1: { avoid: 'А', adaptation: 'Б' },
        2: { cooperation: 'Б', compromise: 'А' },
        3: { rivalry: 'А', adaptation: 'Б' },
        4: { compromise: 'А', adaptation: 'Б' },
        5: { cooperation: 'А', avoid: 'Б' },
        6: { rivalry: 'Б', cooperation: 'А' },
        7: { avoid: 'А', compromise: 'Б' },
        8: { rivalry: 'А', cooperation: 'Б' },
        9: { rivalry: 'Б', avoid: 'А' },
        10: { rivalry: 'А', compromise: 'Б' },
        11: { cooperation: 'А', adaptation: 'Б' },
        12: { avoid: 'А', compromise: 'Б' },
        13: { rivalry: 'Б', compromise: 'А' },
        14: { rivalry: 'Б', cooperation: 'А' },
        15: { adaptation: 'А', avoid: 'Б' },
        16: { rivalry: 'Б', adaptation: 'А' },
        17: { rivalry: 'А', avoid: 'Б' },
        18: { adaptation: 'А', compromise: 'Б' },
        19: { cooperation: 'А', avoid: 'Б' },
        20: { cooperation: 'А', compromise: 'Б' },
        21: { cooperation: 'Б', adaptation: 'А' },
        22: { compromise: 'А' },
        23: { cooperation: 'А', avoid: 'Б' },
        24: { adaptation: 'А', compromise: 'Б' },
        25: { rivalry: 'А', adaptation: 'Б' },
        26: { cooperation: 'Б', compromise: 'А' },
        27: { avoid: 'А', adaptation: 'Б' },
        28: { rivalry: 'А', cooperation: 'Б' },
        29: { compromise: 'А', avoid: 'Б' },
        30: { cooperation: 'Б', adaptation: 'А' }
      };


      this.answersThomasQuestionnaire.forEach(answer => {
        const questionNum = parseInt(answer.question);
        const userChoice = answer.choice;
        const questionRules = scoringRules[questionNum];

        for (const [quality, correctChoice] of Object.entries(questionRules)) {
          if (userChoice === correctChoice) {
            scores[quality]++;
          }
        }
      });

      const thomasQuestionnaireData = {
        score: scores,
        answers: this.answersThomasQuestionnaire,
      }

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      params.answer_id = {...params.answer_id, thomasQuestionnaireData}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })

        clearInterval(this.timerInterval);

        localStorage.removeItem('quiz-params-15-17')
        localStorage.removeItem('timerComplexExplanations')
        localStorage.removeItem('timerCreativitySubtest1')
        localStorage.removeItem('timerCreativitySubtest2')
        localStorage.removeItem('currentSubtest')
        localStorage.removeItem('timerCreativitySubtest3')
        localStorage.removeItem('timerHiddenForm')
        localStorage.removeItem('timerIncorrectPhrase')
        localStorage.removeItem('timerFunnyStories')
        localStorage.removeItem('timerThomasQuestionnaire')
        localStorage.removeItem('current-test')


      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      await this.exit()

    },
    async exit() {
      const quizToken = localStorage.getItem('quiz-token')
      const res = await this.$http.post(`${API_ROOT}/api/quiz/finish/${quizToken}`, {
        remaining_time: 2400000
      })

      if (res.body.status !== 'success') {
        Vue.toastr({
          message: 'Error',
          description: res.body,
          type: 'error'
        })
      }

      const userQuizId = localStorage.getItem('user_quiz_id')

      setTimeout(() => {
        window.location.href=`${window.QABILET_ROOT}test-results-15-17/${userQuizId}/${quizToken}`
      }, 2500)
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerThomasQuestionnaire', savedTimer);

        if (--timer < 0) {
          this.sendThomasQuestionnaireResults(true)
          this.exit()
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerThomasQuestionnaire')) {
      this.startTimer(localStorage.getItem('timerThomasQuestionnaire'))
    } else {
      this.startTimer(300)
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
